import classNames from "classnames";
import React from "react";
import BlockContent from "../BlockContent/BlockContent";
import { SanityExternalCollaborator } from "../../model/common";
import { imageUrlBuilder } from "../../utils/images";
import styles from "./ExternalCollaboratorCredit.module.scss";
import { TextVariant } from "../Text/Text";

type Size = "sm" | "lg";

const ExternalCollaboratorCredit = ({
  className,
  size = "sm",
  sponsor: { _rawText, logo },
  textVariant = "defaultTiny"
}: {
  size?: Size;
  className?: string;
  textVariant?: TextVariant;
  sponsor: SanityExternalCollaborator;
}) => {
  const logoWidth = size === "sm" ? 111 : 222;
  const logoHeight =
    logoWidth / (logo?.asset.metadata.dimensions.aspectRatio ?? 1);
  return (
    <div className={classNames(styles.root, className, size)}>
      {logo && logo.asset && (
        <img
          height={logoHeight}
          width={logoWidth}
          alt={logo.alt}
          src={
            imageUrlBuilder
              .image(logo)
              .fit("fill")
              .width(logoWidth * 2)
              .bg("fff")
              .url()!
          }
        />
      )}
      {_rawText && (
        <BlockContent bodyVariant={textVariant} content={_rawText} />
      )}
    </div>
  );
};

export default ExternalCollaboratorCredit;
